/* ==========================================================================
    header-anim-2
    ========================================================================== */

// ==== Variables hauteurs header

$height-header-anim-2-init: 10rem; // hauteur initial
$height-header-anim-2-affix: 5rem; // hauteur affix
$height-navbar-ecommerce: 0; // si pas de navbar e-commerce
// $height-navbar-ecommerce: 2rem; // si navbar e-commerce activee

// ==== Global

.navbar-collapse {
	background-color: #fff;
	@media(min-width: $grid-float-breakpoint) {
		background-color: $navbar-default-bg;
	}
}

.header-anim-2 {
	position: relative;
	top: $height-navbar-ecommerce;
	z-index: 1000;
	background-color: #fff;
	
	.navbar {
		border: 0;
	}
	
	@media(min-width: $grid-float-breakpoint) {
		background-color: $navbar-default-bg;
		height: $navbar-height;
	}
}

.background-header {
	height: auto;
	// background: transparent;
	@media(min-width: $grid-float-breakpoint) {
		height: 10rem;
	}
}

// ==== Container logo


.header-anim-2__logo {
	position: absolute;
	z-index: 1100;
	top: 0;
	left: -2rem;
	height: 100%;
	padding: .5rem 2rem;
	// background: white;
	// border-right: solid .5rem $brand-second;
	// transform: skewX(-13deg);

	
	@media(min-width: $grid-float-breakpoint) {
		// border-radius: .3rem;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 18rem;
		height: $height-header-anim-2-init;
		transition: height ease .2s;
		transform-origin: center;
	}
}

// ==== Hauteur du logo

.header-anim-2__img-logo--animated {
	// transform: skewX(13deg);
	display: block;
	max-height: 100%;
	background-color: transparent;
	border-radius: .5rem;
	margin-top: .3rem;

	@media(min-width: $grid-float-breakpoint) {
		width: 100%;
		object-fit: contain;
		height: $height-header-anim-2-init;
		transition: height ease .2s;
	}
}

// ==== Hauteur du block navbar

.header-anim-2__nav {
	width: 100%;
	height: $navbar-height;

	@media(min-width: $grid-float-breakpoint) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: $height-header-anim-2-init;
		transition: height ease .2s;
	}
}

// ==== Animations (affix)

.header-anim-2__inner {
	@extend .bg-footer;
	position: relative;
	left: 0;
	right: 0;
	// background: $brand-second;

	&.affix {
		position: relative;
	}
}

@media(min-width: $grid-float-breakpoint) {

	.header-anim-2 {
		position: fixed;
		top: $height-navbar-ecommerce;
		height: $navbar-height;
		background-color: transparent;
		z-index: 1000;
	
		@media(min-width: $grid-float-breakpoint) {
			height: $height-header-anim-2-init;
		}
	}

	.header-anim-2__inner {
		position: fixed;
		height: $height-header-anim-2-init;
		top: $height-navbar-ecommerce;
		transition: height ease .2s, background-color ease .2s;

		.navbar-default {
			background-color: transparent;
			border-color: transparent;

			.navbar-nav>li>a {
				color: white;
				transition: color ease .2s;

				&:hover,
				&:focus,
				&:active {
					color: lighten($brand-second, 15%);
				}
			}

			.navbar-nav>li.active>a {
				color: lighten($brand-primary, 15%);

				&:hover {
					color: $brand-tierce;
				}
			}
		}

		&.affix {
			position: fixed;
			height: $height-header-anim-2-affix;
			top: $height-navbar-ecommerce;
			background-color: white;

			.navbar-default {
				background-color: transparent;
				border-color: transparent;

				.navbar-nav>li>a {
					color: white;

					&:hover {
						color: $navbar-default-link-hover-color;
					}
				}

				.navbar-nav>li.active>a {
					color: $navbar-default-link-active-color;

					&:hover {
						color: $navbar-default-link-hover-color;
					}
				}
			}

			.header-anim-2__nav {
				height: $height-header-anim-2-affix;
			}

			.header-anim-2__logo {
				height: $height-header-anim-2-affix;
			}

			.header-anim-2__img-logo--animated {
				height: 3.5rem;
			}
		}
	}
}
